<template>
  <BaseLayout title="充值提现" bg="#1989fa" fg="#FFF" notitle>

    <van-row class="stat" gutter="2" justify="space-around" align="center">
      <van-col span="12">
        <div class="title"><van-tag size="large" color="#1989fa">累计充值</van-tag></div> 
        <number-roll :number="state.stat.deposit" fg="#FFF" size="21px"><template #prepend>$</template></number-roll>
      </van-col>
      <van-col span="12">
        <div class="title"><van-tag size="large" color="#1989fa">累计提现</van-tag></div>
        <number-roll :number="Math.abs(state.stat.withdrawal)" fg="#FFF" size="21px"><template #prepend>$</template></number-roll>
      </van-col>
    </van-row>

    <van-tabs class="info" v-model:active="state.active" color="#1989fa" offset-top="45" type="card" sticky swipeable animated>
      
      <van-tab title="充值">
        <div class="deposit">
          <p><van-tag type="primary" size="large" round>链类型：TRC20&nbsp;&nbsp;&nbsp;币类型：USDT</van-tag></p>
          <van-image :src="'https://api.qrserver.com/v1/create-qr-code/?size=300x300&margin=10&ecc=H&data=' + account" width="200" height="200"></van-image>
          <p>{{account}}</p>
          <van-button round @click="$copy(account)">复制地址</van-button>&nbsp;<van-button round @click="onQuery">我已充值，刷新记录</van-button>
          <div class="notice">温馨提示：转出前请仔细核对地址，并务必使用TRC20的钱包转账，一经转出不可撤销，转错不可找回。转出后请点击上方的按钮刷新充值记录。</div>
        </div>
      </van-tab>

      <van-tab title="提现">

        <van-form class="withdrawal" @submit="onSubmit">
          <van-row align="center">
            <van-col span="7">
              提币地址：
            </van-col>
            <van-col span="17">
              <van-field v-model="state.address" type="textarea" autosize rows="2" placeholder="必须是 TRC20 地址，并请仔细核对，填错概不负责" />
            </van-col>
            <van-col span="7">
              提币数量：
            </van-col>
            <van-col span="17">
              <van-slider class="slider" v-model="state.amount" min="10" max="1000" step="1" active-color="#39F">
                <template #button>
                  <div class="slider-btn">{{ state.amount }}</div>
                </template>
              </van-slider>
            </van-col>
            <van-col span="24">
              <van-button round block type="primary" native-type="submit">申 请</van-button>
            </van-col>
          </van-row>
        </van-form>

        <van-divider class="title">最近的申请记录</van-divider>

        <van-list v-model:loading="state.loading" :finished="state.finished" loading-text="正在请求数据 ..." finished-text="没有更多了" :immediate-check="false" @load="onLoad">
          <van-empty v-show="state.list.length == 0"></van-empty>
          
          <van-cell-group class="block" :border="false" v-for="(item,index) in state.list" :key="index">
            <van-cell class="item" :border="false" :title="item.remark" :value="item.amount + ' U'"></van-cell>
            <van-cell class="account" :border="false" :value="item.account" is-link @click="$copy(item.account)"></van-cell>
            <van-cell class="dt" :border="false" :title="item.dt">
              <template #extra><span :style="{color:item.color}">{{item.status}}</span>&nbsp;&nbsp;#{{item.id}}</template>
            </van-cell>
            
          </van-cell-group>

        </van-list>
      </van-tab>

    </van-tabs>

  </BaseLayout>
</template>

<style scoped>
.stat {
  min-height: 90px;
  margin: 0 20px 10px 20px;
  background: #39F;
  border-radius: 5px;
  text-align: center;
}
.stat .title {
  margin: 5px 0;
}


.info {
  min-height: 580px;
  padding: 10px;
  background: #F9F9F9;
  border-radius: 20px;
  border: solid 5px #1989fa;
}

.deposit {
  margin: 40px 10px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}
.notice {
  margin-top: 30px;
  text-align: left;
}

.withdrawal {
  margin-top: 20px;
  padding: 5px 15px;
  height: 180px;
  background: #FFF;
  border-radius: 10px;
  border: solid 1px #EEE;
  font-size: 14px;
}
.slider {
  margin:30px 10px 30px 0;
}
.slider-btn {
  width: 26px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: #39F;
  border-radius: 100px;
}
.title {
  margin: 30px 5px 0px 5px;
  color: #999;
  border-color: #999;
  font-size: 14px;
}
.block {
  margin-top: 20px;
  padding-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.item {
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}
.account {
  font-size: 12px;
  text-align: right;
}
.dt {
  height: 30px;
  padding-top: 2px;
  font-size: 12px;
}

</style>

<script>
import { reactive } from 'vue'
import { mapGetters } from 'vuex'
import BaseLayout from '@/components/BaseLayout.vue'
import NumberRoll from '@/components/NumberRoll.vue'

export default {
  components: {BaseLayout, NumberRoll},

  props: {
    action: {
      type: String,
      default: "deposit",
    },
  },

  setup(){
    const state = reactive({
      stat: {
        balance: 0,
        freez: 0,
        transfer: 0,
        deposit: 0,
        withdrawal: 0,
        consume: 0,
        reward: 0,
      },

      address: "",
      amount: 0,

      active: 0, // 当前激活的标签
      loading: false,
      finished: false,

      list: [],   // 列表
      offset: 0,  // 记录偏移量
      limit : 20, // 每页记录数量
    });

    return {state};
  },

  created() {
    this.state.active = this.action == "withdrawal" ? 1 : 0;
    this.onChange();
  },

  mounted() {
    this.$store.commit("changeSticky", true);
  },

  computed: {
    ...mapGetters(["authorized", "account"]),
  },

  methods: {
    onChange() {
      this.state.loading = true;
      this.state.finished = false;
      this.state.offset = 0;
      this.state.list = [];
      this.onLoad();
      this.$http.get("/property/summary?subject=cash").then((data) => this.state.stat = data);
    },
    onLoad() {
      this.$http.get("/property/cashlist?type=withdrawal&offset=" + this.state.offset + "&limit=" + this.state.limit).then((data) => {
        if (data.length == 0 || data.length < this.state.limit)
          this.state.finished = true;
        this.state.loading = false;
        this.state.offset += data.length;

        for (let i in data){
          let item = data[i];
          this.state.list.push({...item, ...{
            amount: item.amount.replace(/(\.\d\d)(\d+)$/, "$1"),
            status: ["待审核", "已审核","被拒绝"][item.status],
            color : ["#999", "#090", "#F66"][item.status],
          }});
        }
      });
    },

    onQuery(){
      this.$http.post("/property/cashquery").then((data) => {
        if (!data.total) return;
        this.$dialog.alert({
          message: "查询已到账：" + data.total + " USDT",
          theme: "round-button",
        });
        this.$init();
        this.onChange();
      });
    },

    onSubmit(){
      if (!/^T[\w]{20,}$/.test(this.state.address))
        return this.$toast("提币地址不合要求");
      
      this.$http.post("/property/cashrequest?type=withdrawal&amount=" + this.state.amount).then(() => {
        this.onChange();
      });
    },

  },
}
</script>
